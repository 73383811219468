:root {
    --color-darkred: #1a070e;
    --color-logoblue: #697db4;
    --color-logogreen: #36b696;
    --color-textlight: #fffeed;
    --color-darkblue: #2D354D;
    --bs-light-rgb: 255, 254, 237;
    --gradient-logo: linear-gradient(333deg, var(--color-logoblue) 0%, var(--color-logogreen) 100%);
}

@font-face {
    font-family: 'KnewaveRegular';
    src: url('font-knewave/knewave-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.wrapper {
    background: var(--color-darkred);
    /*background: linear-gradient(330deg, rgba(105,125,180,1) 0%, rgba(89,142,171,0.9332107843137255) 29%, rgba(91,140,172,1) 30%, rgba(79,154,165,1) 51%, rgba(67,167,158,1) 70%, rgba(68,166,159,0.804359243697479) 71%, rgba(54,182,150,1) 100%);*/
    border-radius: 5px;
    background: var(--gradient-logo);
    padding: .06em;
}

.plaubo .text-light span {
    color: var(--color-textlight) !important;
}

#root>.wrapper>.plaubo.card.bg-dark,
#root>.wrapper>.plaubo.card.bg-dark>.card-header{
    background-color: transparent !important;
    border: none;
}

body, .plaubo.bg-dark, .plaubo .bg-dark,
#root>.wrapper>.plaubo.card.bg-dark>.card-body {
    background-color: var(--color-darkred) !important;
    border-radius: 5px;
}

#appName {
    font-family: "KnewaveRegular";
    color: var(--color-darkred);
    font-size: 3em;
    line-height: .9em;
    padding: 0;
    margin: 0;
    position: relative;
    left: 8px;
    top: .1em;
}

#versionNumber {
    font-family: ui-monospace,    Menlo, Monaco,    "Cascadia Mono", "Segoe UI Mono",    "Roboto Mono",    "Oxygen Mono",    "Ubuntu Monospace",    "Source Code Pro",    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;
    font-size: 12px;
    font-weight: bold;
    float: right;
    position: relative;
    top: 42px;
    letter-spacing: -1px;
    opacity: .15;
}

.plaubo.card {
    border: none;
}

.plaubo .btn.btn-primary {
    background: var(--color-logoblue);
    background: var(--gradient-logo);
    border-color: rgba(0, 0, 0, .3);
    color: var(--color-darkred);
}

.plaubo .btn.btn-secondary {
    background-color: var(--color-logoblue);
    border-color: rgba(0, 0, 0, .3);
    color: var(--color-darkred);
}

.plaubo select, .plaubo select option {
    background-color: var(--color-darkred);
    color: var(--color-textlight);
    border-color: var(--color-logoblue);
}

.plaubo .btn.btn-primary svg path {

}
.plaubo .btn.btn-primary svg {
    color: var(--color-darkred);
}

.plaubo .form-label {
    margin-bottom: 0;
}

#currentlyPlaying>* {
    float: left;
}

#currentlyPlaying>div.text-light {
    margin-left: .75em;
    width: 80%;
}

.text-clip {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.modal-body .list-group-flush .text-wrap {
    padding-left: 0;
    padding-right: 0;
}

.text-wrap button {
    float: left;
}

.text-wrap span.bookTitle {
    padding-left: .2rem;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    line-height: 2em;
}

.plaubo .container-fluid {
    padding-right: 0;
    padding-left: 0;
}

.card.plaubo {
    margin: .06em;
}

.card.plaubo .card-header {
    padding: 0;
}

.card.plaubo .card-body.player.loading {
    max-height: 4em;
    overflow: hidden;
}

.card.plaubo .card-body.player.loaded {
    max-height: 100em;
    overflow: hidden;
}

.card.plaubo .card-body.player {
    transition-property: max-height;
    transition-duration: 2s;
    /*transform-style: flat;*/
    transition-timing-function: ease-in-out;
}

.plaubo .progress .progress-bar.bg-primary {
    background-color: var(--color-logogreen) !important;
}
.plaubo .progress .progress-bar.bg-dark.progress-bar-striped,
.plaubo .progress .progress-bar.bg-dark.progress-bar-animated {
    background-color: var(--color-logoblue) !important;
    border-radius: 0;
    background-image: linear-gradient(
            135deg,rgba(54,182,150,.6) 25%,transparent 25%,transparent 50%,rgba(54,182,150,.6) 50%,rgba(54,182,150,.6) 75%,transparent 75%,transparent);
}
.plaubo .progress .progress-bar.bg-dark.progress-bar-animated {
    /*animation: 1s linear infinite progress-bar-stripes;*/
}
.plaubo .progress .progress-bar.bg-dark {
    background-color: var(--color-logoblue) !important;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.plaubo .input-group>* {
    background-color: transparent;
    border-color: var(--color-logoblue);
    color: var(--color-logoblue);
}

.plaubo .input-group>input {
    color: var(--color-logogreen)
}

.modal-dialog, .modal-dialog div.bg-dark {
    background-color: var(--color-darkred);
    border-color: var(--color-darkred);
}

.modal-dialog .modal-body,
.modal-dialog .modal-footer{
    padding: 0;
}

.modal-dialog .modal-header,
.modal-dialog .card-body {
    padding: .75rem;
}

.modal-dialog .modal-header,
.modal-dialog .modal-body,
.modal-dialog .modal-footer {
    border-radius: 0;
    border-color: transparent;
}

.modal-dialog .modal-header {
    background-color: var(--color-darkred);
    background: var(--gradient-logo) !important;
}

.modal-dialog .card-body ul.nav.nav-tabs {
    display: none;
}

.modal-dialog .card-body {
    margin: 4px;
    border: 2px solid var(--color-logogreen) !important;
}

.modal-dialog .card-header .nav.card-header-tabs {
    /*background: var(--gradient-logo);*/
}

.modal-dialog .card-header .nav.card-header-tabs .nav-item a.nav-link {
    border: 2px solid var(--color-logoblue);
    background-color: var(--color-darkred);
    color: var(--color-logoblue);
    position: relative;
    top: 6px;
    z-index: 9;
    margin-left: .2em;
    margin-right: .2em;
    border-bottom-color: var(--color-logogreen)
}

.modal-dialog .card-header .nav.card-header-tabs .nav-item a.nav-link.active {
    /*background: var(--gradient-logo);*/
    background-color: transparent;
    color: var(--color-logogreen);
    border-color: var(--color-logogreen);
    border-bottom-color: var(--color-darkred)
}

.modal-dialog .accordion-flush .accordion-button,
.modal-dialog .accordion-flush .accordion-body {
    padding: .75rem .2rem;
    background-color: var(--color-darkred);
    color: var(--color-darkred);
}

.modal-dialog .accordion-flush .accordion-button {
    background: var(--gradient-logo);
}

.modal-dialog .accordion-flush .accordion-body .list-group-item {
    padding: .2rem .5rem;
    border-bottom: none;
}

.modal-dialog .accordion-flush .accordion-body .list-group-item.text-wrap {
    padding: .2rem 0;
}

#fakeaudio {
    display: none;
}


.plaubo input[type=range] {
    height: 23px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
}
.plaubo input[type=range]:focus {
    outline: none;
}
.plaubo input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px var(--color-textlight);
    background: var(--color-darkblue);
    border-radius: 8px;
    border: 0px solid #1F1F1F;
}
.plaubo input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px var(--color-textlight);
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 16px;
    background: var(--gradient-logo);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4.5px;
}
.plaubo input[type=range]:focus::-webkit-slider-runnable-track {
    background: var(--color-darkblue);
}
.plaubo input[type=range]::-moz-range-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px var(--color-textlight);
    background: var(--color-darkblue);
    border-radius: 8px;
    border: 0px solid #1F1F1F;
}
.plaubo input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px var(--color-textlight);
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 16px;
    background: var(--gradient-logo);
    cursor: pointer;
}
.plaubo input[type=range]::-ms-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}
.plaubo input[type=range]::-ms-fill-lower {
    background: var(--color-darkblue);
    border: 0px solid #1F1F1F;
    border-radius: 16px;
    box-shadow: 0px 0px 0px var(--color-textlight);
}
.plaubo input[type=range]::-ms-fill-upper {
    background: var(--color-darkblue);
    border: 0px solid #1F1F1F;
    border-radius: 16px;
    box-shadow: 0px 0px 0px var(--color-textlight);
}
.plaubo input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px var(--color-textlight);
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 16px;
    background: var(--gradient-logo);
    cursor: pointer;
}
.plaubo input[type=range]:focus::-ms-fill-lower {
    background: var(--color-darkblue);
}
.plaubo input[type=range]:focus::-ms-fill-upper {
    background: var(--color-darkblue);
}


.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 22px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-darkblue);
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 4px;
    background-color: var(--color-textlight);
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background: var(--gradient-logo);
}

input:focus + .slider {
    box-shadow: 0 0 1px var(--color-logoblue);
}

input:checked + .slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
